<section class="bg-login d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-center mt-4">
            <div class="col-lg-4">
                <div class="bg-white p-4 rounded">
                    <div class="text-center">
                        <h4 class="fw-bold mb-3">Globing</h4>
                    </div>
                    <form class="login-form">
                        <div class="row">
                            <div class="col-lg-12 mt-2">
                                <input type="email" class="form-control" placeholder="Email" required="">
                            </div>
                            <div class="col-lg-12 mt-2">
                                <input type="password" class="form-control" placeholder="Password" required="">
                            </div>
                            <div class="col-lg-12 mt-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Remember me
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-3 mb-4">
                                <button class="btn btn-primary w-100">Sign in</button>
                            </div>
                            <div class="txet-center">
                                <p class="mb-0 mt-2 text-center">
                                    <a routerLink="/password_forget" class="text-dark fw-bold">Forgot your
                                        password ?</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="text-center mt-3">
                    <p><small class="text-white me-2">Don't have an account ?</small> <a routerLink="/signup" class="text-white fw-bold">Create an account</a></p>
                </div>
            </div>
        </div>
    </div>
</section>