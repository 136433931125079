<section class="bg-login d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-center mt-4">
            <div class="col-lg-4">
                <div class="bg-white p-4 mt-5 rounded">
                    <div class="text-center">
                        <h4 class="fw-bold mb-3">Globing</h4>
                    </div>
                    <h6 class="text-center text-muted fw-normal forgot-pass-txt">Enter your email address
                        and we'll send you an email with instructions to reset your password.</h6>
                        <form class="login-form">
                            <div class="row">
                                <div class="col-lg-12 mt-3">
                                    <input type="email" class="form-control" placeholder="Email" required="">
                                </div>
                                <div class="col-lg-12 mt-4 mb-2">
                                    <button class="btn btn-primary w-100">Reset your Password</button>
                                </div>
                            </div>
                        </form>
                </div>
                <div class="text-center mt-3">
                    <p><small class="text-white me-2">Already have account?</small> <a routerLink="/login" class="text-white fw-bold">Sign in</a></p>
                </div>
            </div>
        </div>
    </div>
</section>